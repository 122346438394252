<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'
import { useMediaQuery } from '@vueuse/core'
import {
  DialogClose,
  DialogContent,
  type DialogContentEmits,
  type DialogContentProps,
  DialogOverlay,
  DialogPortal,
  useForwardPropsEmits,
} from 'radix-vue'
import { X } from 'lucide-vue-next'
import { cn } from '@/lib/utils'

const props = defineProps<DialogContentProps & { class?: HTMLAttributes['class'] }>()
const emits = defineEmits<DialogContentEmits>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const forwarded = useForwardPropsEmits(delegatedProps, emits)

const isDesktop = useMediaQuery('(min-width: 768px)');
const startClasses = computed(() => {
  if(isDesktop.value) return ' fixed left-1/2 top-1/2 z-50 grid w-full sm:max-w-lg -translate-x-1/2 -translate-y-1/2 gap-4 border border-neutral-200 bg-white p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg dark:border-neutral-800 dark:bg-neutral-950'
  return ' flex flex-col translate-y-0 !rounded-none fixed z-50 bg-background p-6 shadow-lg transition ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:duration-300 data-[state=open]:duration-500 inset-y-0 right-0 h-full border-l data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right sm:max-w-sm w-full'
})

</script>

<template>
  <DialogPortal>
    <DialogOverlay
      class="ys-dialog-overlay"
    />
    <DialogContent
      v-bind="forwarded"
      :class="
        cn(
          startClasses,
          props.class,
        )"
    >
      <slot />
    </DialogContent>
  </DialogPortal>
</template>
